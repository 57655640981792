<div *ngIf="gene" class="section section-hero">
  <div class="section-inner">
    <div class="container-sm">
      <h1 class="gene-hero-heading">
        {{ gene.hgnc_symbol || gene.ensembl_gene_id }}
      </h1>
      <h2 class="gene-hero-name">
        {{ gene.name }}
      </h2>
      <div *ngIf="showNominationsOrTEP()" class="gene-hero-nominated">{{ getNominationText() }}</div>
      <p class="gene-hero-summary">
        {{ getSummary(true) }}
      </p>
      <p class="gene-hero-provider">
        {{ getSummary() }}
      </p>
      <ng-container *ngIf="gene.bio_domains || getAliases() || getEnsemblUrl() !== ''">
        <hr />
        <div *ngIf="gene.bio_domains" class="gene-hero-biodomains">
          <h4 class="gene-hero-biodomains-heading">Biological Domains</h4>
          <p class="aliases">
            {{ getBiodomains() }}
          </p>
        </div>
        <div *ngIf="gene.druggability && gene.druggability.pharos_class" class="gene-hero-pharos">
          <h4 class="gene-hero-pharos-heading">Pharos Class</h4>
          <p>{{ gene.druggability.pharos_class.join(', ') }}</p>
        </div>
        <div class="gene-hero-aliases">
          <h4 class="gene-hero-aliases-heading">Also known as</h4>
          <ng-container *ngIf="getEnsemblUrl() !== ''">
            <p class="aliases">
              <a [href]="getEnsemblUrl()" target="_blank">{{ gene.ensembl_gene_id }}</a>
              <span *ngIf="gene.ensembl_info.ensembl_release">
                (Ensembl Release {{ gene.ensembl_info.ensembl_release }})
              </span>
            </p>
          </ng-container>
          <p *ngIf="getEnsemblUrl() === ''" class="aliases">
            <span *ngIf="getEnsemblUrl() === ''">{{ gene.ensembl_gene_id }}</span>
          </p>
          <p *ngIf="gene.ensembl_info.ensembl_possible_replacements.length > 0" class="aliases">
            <a [href]="getPossibleReplacementsURL()">Possible replacement value<span *ngIf="gene.ensembl_info.ensembl_possible_replacements.length > 1">s</span></a>:
            {{ gene.ensembl_info.ensembl_possible_replacements.join(', ')}}
          </p>
          <p *ngIf="gene.uniprotkb_accessions" class="aliases">
            UNIPROTKB 
            <ng-container *ngFor="let accession of gene.uniprotkb_accessions; let isLast = last" class="aliases">
              <a [href]="getUniProtKBLink(accession)" target="_blank">{{ accession }}</a><span *ngIf="!isLast">, </span>
            </ng-container>
          </p>
          <p *ngIf="gene.alias.length > 0" class="aliases">
            {{ getAliases() }}
          </p>
        </div>
      </ng-container>
    </div>
  </div>
</div>
